<template>
  <div class="w-100 text-center mt-4">
    <router-link class="" :to="{ name: 'Home'}">
      <img alt="Logo" height="60" src="@/assets/logo-1.png">
    </router-link>
  </div>
  <div id="login" class="card mt-3">
    <div class="card-body">
      <template v-if="view=='ForgotPassword'">
        <div v-if="error" class="alert alert-danger">Email not valid</div>
        <h2 class="text-center h4">Forgot Password</h2>
        <p>Enter the email address associated with your Dxboard account.</p>
        <form @submit.prevent="submit()">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" v-model.trim="v$.email.$model">
                <div v-if="v$.email.$dirty">
                  <small class="form-text text-danger" v-if="v$.email.required.$invalid">Required field</small>
                  <small class="form-text text-danger" v-if="v$.email.email.$invalid">Format incorrect</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Next</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt=""></button>
            </div>
          </div>
        </form>
      </template>
      <template v-if="view=='ConfirmForgotPassword'">
        
        <form @submit.prevent="ConfirmForgotPassword()">
          <div class="row">
            <div class="col-12">
              <h2 class="h4">Set your password</h2>
              <p>We have sent you a verification code to your email. Please log in to your email and enter the code you received.</p>
              <div class="form-group">
                <label for="new_password">New Password</label>
                <input type="password" class="form-control" id="new_password" v-model.trim="v$.new_password.$model">
                <div v-if="v$.new_password.$dirty">
                  <small class="form-text text-danger" v-if="v$.new_password.awsRegex.$invalid">Format incorrect</small>
                  <small class="form-text text-danger" v-if="v$.new_password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="rnew_password">Repeat Password</label>
                <input type="password" class="form-control" id="rnew_password" v-model.trim="v$.rnew_password.$model">
                <div v-if="v$.rnew_password.$dirty">
                  <small class="form-text text-danger" v-if="v$.rnew_password.awsRegex.$invalid">Format incorrect</small>
                  <small class="form-text text-danger" v-if="v$.rnew_password.sameAs.$invalid">Passwords do not match</small>
                  <small class="form-text text-danger" v-if="v$.rnew_password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="new_password">Verification Code</label>
                <input type="text" class="form-control" id="code_verification" v-model.trim="v$.code_verification.$model">
                <div v-if="v$.code_verification.$dirty">
                  <small class="form-text text-danger" v-if="v$.code_verification.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Continue</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt=""></button>
            </div>
          </div>
        </form>
      </template>
      <template v-if="view=='confirmation'">
        <div class="row">
            <div class="col-12 text-center">
              <p>You have successfully reset your password. You can now log in.</p>
              <router-link class="btn btn-primary" :to="{ name: 'login' }"> Sign In </router-link>
            </div>
          </div>
      </template>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf, helpers, sameAs, numeric  } from '@vuelidate/validators'

const awsRegex = helpers.regex(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/)

export default {
  name: 'ForgotPassword',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      email: '',
      code_verification: '',
      loading: false,
      error: false,
      view: 'ForgotPassword',
      new_password: '',
      rnew_password: ''
    };
  },
  created() {
    if(this.$isLogged()){
      window.location.href = "/dashboard";
    }
  },
  mounted(){
    this.$emit('widgetLoaded');
  },
  validations () {
    return {
      email: {
        required,
        email
      },
      code_verification: {
        required: requiredIf(function(){ return this.view === 'ConfirmForgotPassword' })
      },
      new_password: {
        awsRegex,
        required: requiredIf(function(){ return this.view === 'ConfirmForgotPassword' }),
      },
      rnew_password: {
        awsRegex,
        sameAs: sameAs(this.new_password),
        required: requiredIf(function(){ return this.view === 'ConfirmForgotPassword' }),
      }
    }
  },
  methods: {

    submit() {
      const bodyToSend = {
        email: this.email,
      };
      this.loading = true;
      this.error = false;

      axios.post('/v2/auth/forgot-password', bodyToSend)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if(response.data.statusCode !== 200){
            this.error = true;
          }
          else{
            this.view = 'ConfirmForgotPassword';
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    ConfirmForgotPassword(){
      const bodyToSend = {
        email: this.email,
        password: this.new_password,
        code: this.code_verification,
      };
      this.loading = true;
      this.error = false;

      axios.post('/v2/auth/forgot-password-confirmation', bodyToSend)
        .then((response) => {
          this.loading = false;
          if(response.data.statusCode !== 200){
            this.error = true;
          }
          else{
            this.view = 'confirmation';
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
